
import { renderData } from "@libraryHelpers/dataComponents.js";
import { Loader } from '@googlemaps/js-api-loader'

export default {
	name: "CoreBlockMap",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			apiKey: process.env.NUXT_ENV_GOOGLE_MAPS_KEY,
			loaded: false,
			map: null,
			showGoogleMap: false,
			marker: {}
		};
	},
	mounted(){
		const loader = new Loader({
			apiKey: process.env.NUXT_ENV_GOOGLE_MAPS_KEY,
			version: 'weekly'
			})

		loader.load().then(() => {
			
			const styles = {
				default: [],
				hide: [
					{
						featureType: "poi",
						stylers: [{ visibility: "off" }],
					},
				]
			};

			const { Map, Geocoder, GeocoderStatus, Marker, InfoWindow, Size } = google.maps; 

			this.map = new Map(this.$refs.map, {
				zoom: 18
			});

			this.map.setOptions({ styles: styles["hide"] });

			const address =
				`${this.line1} ${this.line2}, ${this.city}, ${this.state}, ${this.zip}`

			const geocoder = new Geocoder();
			geocoder.geocode({ address }, (results, status) => {
				if (status === GeocoderStatus.OK) {
					const location = results[0].geometry.location;
					this.map.setCenter(location);
					this.map.setZoom(18);
					this.marker = new Marker({
						map: this.map,
						position: location,
						title: address
					});
					const infoWindow = new InfoWindow({
						content: 
							`<div class='poi-info-window'>
								<p class='info-address'>${address}</p>
								<div>
									<a href='https://www.google.com/maps/search/${encodeURIComponent(address)}' target='_blank' class='info-map-link' aria-label='open Google Maps in a new tab'>view larger map</a>
									 | 
									<a href='https://www.google.com/maps/dir//${encodeURIComponent(address)}' target='_blank' class='info-directions-link' aria-label='open Google Maps Directions in a new tab'>get directions</a>
								</div>
							</div>`
					})
					this.marker.addListener("click", () => {
						infoWindow.open({
						anchor: this.marker,
						map: this.map,
						});
					});
				} 
			});			
		})
	},
	computed: {
		address() {
			return this.settings.address || {};
		},
		line1() {
			return renderData(this.address.line1, this.dataSite);
		},
		line2() {
			return renderData(this.address.line2, this.dataSite);
		},
		city() {
			return renderData(this.address.city, this.dataSite);
		},
		state() {
			return renderData(this.address.state, this.dataSite);
		},
		zip() {
			return renderData(this.address.zip, this.dataSite);
		},
		mapUrl() {
			if(this.map && this.$refs.mapContainer.offsetWidth > 0) {
				const address =
					`${encodeURIComponent(this.line1)} ${encodeURIComponent(this.line2)}, ${encodeURIComponent(this.city)}, ${encodeURIComponent(this.state)}`
				const zoom = 10
				const width = this.$refs.mapContainer.offsetWidth > 640 ? Math.floor(this.$refs.mapContainer.offsetWidth / 2) : this.$refs.mapContainer.offsetWidth
				const height = this.$refs.mapContainer.offsetWidth > 640 ? 250 : 500;
				const scale =  this.$refs.mapContainer.offsetWidth > 640 ? 2 : 1;
				const size = `${width}x${height}`
				return `https://maps.googleapis.com/maps/api/staticmap?center=${address}&markers=${address}&zoom=${zoom}&size=${size}&scale=${scale}&key=${this.apiKey}`
			}
		},
		mapAlt() {
			return `${this.city}, ${this.state}`;
		},
	},
	methods: {
		toggleMap() {
			this.showGoogleMap = true;
			google.maps.event.trigger(this.marker, 'click')
		}
	}
};
